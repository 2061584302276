export const getLineChartOptions: any = (theme: any) => {
	return {
		title: {
			text: 'Hello',
			style: {
				visibility: 'hidden',
			},
		},
		credits: {
			enabled: false,
		},
		chart: {
			plotBackgroundColor: theme.palette.background.light,
			backgroundColor: theme.palette.background.light,
			borderColor: theme.palette.background.light,
			height: 320,
			plotBorderWidth: null,
			className: 'highcharts-color-0',
			// spacingRight: 60,
		},
		yAxis: {
			title: {
				text: 'Revenue',
				style: {
					fontSize: '1rem',
					fontWeight: '600',
					margin: 200,
					color: theme.palette.text.primary,
				},
			},
			labels: {
				align: 'right',
				useHTML: true,
				style: {
					fontSize: '14px',
					whiteSpace: 'nowrap',
					fontFamily: 'Lato',
					color: theme.palette.text.primary,
				},
			},
			lang: {
				noData: 'No data to display',
			},
			noData: {
				style: {
					fontWeight: 'bold',
					fontSize: '15px',
					background: 'red',
					color: theme.palette.text.primary,
				},
			},
			gridLineWidth: theme.palette.mode === 'dark' ? 0.2 : 1,
		},
	};
};

export const getBarGraphOptions: any = (theme: any) => {
	return {
		chart: {
			plotBackgroundColor: theme.palette.background.light,
			backgroundColor: theme.palette.background.light,
			borderColor: theme.palette.background.light,
			plotBorderWidth: null,
			className: 'highcharts-color-0',
			type: 'bar',
			renderTo: 'container',
			height: 320,
		},
		title: {
			// text: 'Revenue Target',
			// align: 'center'
			text: null,
			style: {
				visibility: 'hidden',
				color: theme.palette.text.primary,
				background: theme.palette.background.light,
			},
		},
		xAxis: {
			// className: 'drill_down_bar_Graph',
			categories: ['Africa', 'America', 'Asia', 'Europe', 'Oceania'],
			title: {
				text: null,
				color: theme.palette.text.primary,
			},
			labels: {
				style: {
					color: theme.palette.text.primary,
				},
			},
		},
		yAxis: {
			min: 0,
			title: {
				// text: 'Population (millions)',
				// align: 'high',
				text: null,
				color: theme.palette.text.primary,
			},
			labels: {
				overflow: 'justify',
				style: {
					color: theme.palette.text.primary,
				},
			},
			lang: {
				noData: 'No data to display',
			},
			noData: {
				style: {
					fontWeight: 'bold',
					fontSize: '15px',
					color: theme.palette.text.primary,
				},
			},
			style: {
				color: theme.palette.text.primary,
			},
		},
		tooltip: {
			// valueSuffix: ' millions'
		},
		plotOptions: {
			bar: {
				dataLabels: {
					enabled: true,
				},
			},
		},
		legend: {
			enabled: false,
			// layout: 'vertical',
			// align: 'right',
			// verticalAlign: 'top',
			// x: -40,
			// y: 80,
			// floating: true,
			// borderWidth: 1,
			// backgroundColor: '#FFFFFF',
			// shadow: true
		},
		lang: {
			noData: 'There is no data in the graph ',
		},
		noData: {
			style: {
				fontWeight: 'bold',
				fontSize: '15px',
				backround: 'red',
				color: theme.palette.text.primary,
			},
		},
		credits: {
			enabled: false,
		},
		series: [
			{
				name: '',
				color: '#976EFA',
				width: 20,
				data: [631, 727, 555, 120, 442, 232],
			},
		],
	};
};

export const customStylesTotalSpent = {
	headCells: {
		style: {
			letterSpacing: '0.13px',
			fontSize: '15px !important',
			fontWeight: 'bold !important',
			backgroundColor: 'var(--dataTable-header)',
			paddingLeft: '1.4rem',
			paddingRight: '1.4rem',
			whiteSpace: 'normal !important',
		},
	},
	cells: {
		style: {
			justifyContent: 'center',
			letterSpacing: '0.13px',
			fontSize: '14px !important',
			fontWeight: 'medium !important',
			paddingLeft: '0',
			paddingRight: '1.4rem',
		},
	},
};

export const caseInsensitiveSort = (rowA: any, rowB: any) => {
	if (rowA.account_name == null) {
		return 1;
	}
	if (rowB.account_name == null) {
		return -1;
	}
	if (rowA.account_name.includes('.') || rowA.account_name.includes(',')) {
		return 1;
	}
	if (rowB.account_name.includes('.') || rowB.account_name.includes(',')) {
		return -1;
	}
	const a = rowA.title.toLowerCase();
	const b = rowB.title.toLowerCase();

	if (a > b) {
		return 1;
	}

	if (b > a) {
		return -1;
	}
	return 0;
};

export const nullReplacableKeyword = null;

export const handleColumnName = (colName: any) => {
	// debugger;
	if (colName === nullReplacableKeyword) {
		return '-';
	}
	return colName;
};
